import React, { Component } from "react"
import SEO from "../components/seo"

import Layout from "../components/layout"
import axios from "axios"
import { Metabox } from "../components/styles/NewsHeadingStyles"
import { format } from "date-fns"
import { PageContent } from "../templates/styles/archiveStyles"
import PageHeroLive from '../components/PageHeroLive'

class NewsItem extends Component {
  state = {
    title: "",
    subtitle: "",
    date: new Date(),
    slug: "",
    content: "",
    authorName: "",
    authorSlug: "",
    categoryName: "",
    categorySlug: "",
    media: "",
  }

  async componentDidMount() {
    const getArticle = await axios.get(
      `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/posts?slug=${this.props.location.state.slug}`
    )
    const mediaURL = getArticle.data[0].featured_media === 0
      ? "277"
      : `${getArticle.data[0].featured_media}`;
    const [getAuthor, getCategory, getMedia] = await Promise.all([
      axios.get(
        `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/users/${getArticle.data[0].author}`
      ),
      axios.get(
        `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/categories/${getArticle.data[0].categories[0]}`
      ),
      axios.get(
        `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_URL}/wp-json/wp/v2/media/${mediaURL}`
      ),
    ])
    // Get the largest image that has a max height of 350
    // const images = Object.entries(getMedia.data.media_details.sizes)
    // let maxHeight = 0
    // let imageURL = ''
    // console.log("images:", images)
    // for(let i = 0; i < images.length; i++){
    //   if (maxHeight < images[i][1].height && images[i][1].height <= 350){
    //     maxHeight = images[i][1].height
    //     imageURL = images[i][1].source_url
    //   }
    // }
    const imageURL =
      getMedia.data.media_details.sizes.pageBanner === undefined
        ? getMedia.data.media_details.sizes.full.source_url
        : getMedia.data.media_details.sizes.pageBanner.source_url
    console.log("Url:", imageURL)
    //const suitableImages = images.entries.filter( (image)=> image.height <= 350)
    //console.log("suitableImages1:", suitableImages)
    // suitableImages.sort(function(a, b) {
    //   if(a.height < b.height) return -1;
    //   if(a.height > b.height) return 1;
    //   return 0;
    // })
    // console.log("suitableImages2:", suitableImages)
    this.setState({
      title: getArticle.data[0].title.rendered,
      subtitle: getArticle.data[0].acf.page_banner_subtitle,
      date: getArticle.data[0].date,
      slug: getArticle.data[0].slug,
      content: getArticle.data[0].content.rendered,
      authorName: getAuthor.data.name,
      authorSlug: getAuthor.data.slug,
      categoryName: getCategory.data.name,
      categorySlug: getCategory.data.slug,
      media: imageURL
      // media: getMedia.data.media_details.sizes.pageBanner.source_url
      // media: getMedia.data.guid.rendered
    })
  }
  render() {
    return (
      <Layout>
        <SEO title="Home" keywords={["secta", "tourism", "cornwall"]} />
        {this.state.media !== null ?
        <PageHeroLive img={this.state.media} title='' subTitle='' />
        : '' }
        <div className="container">
          <div className="row" style={{ marginBottom: "40px" }}>
            <div className="col-lg-3"/>
            <PageContent className="col-lg-9">
              <h1>{this.state.title}</h1>
              <h2>{this.state.subtitle}</h2>
              <Metabox>
                <p>Posted by {this.state.authorName} on {format(new Date(this.state.date), 'd MMM yyyy')} in {this.state.categoryName}</p>
              </Metabox>
              <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
            </PageContent>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NewsItem
